import React, { useState } from "react"

import { Container, Col, Form, Row, Button } from "react-bootstrap"
import { Map, TileLayer, Marker, Popup } from "react-leaflet"
import { useForm } from "react-hook-form"
import { Helmet } from "react-helmet"

import axios from "axios"

import Header from "../../components/Header"
import InfoCard from "../../components/InfoCard"
import Footer from "../../components/Footer"
import ClarificaitonModal from "../../components/ClarificationModal"

import { useGlobalState } from "../../global/global"
import { getLanguageFile } from "../../global/langauge.helper"

import SEND_BUTTON_ICON from "../../../assets/images/sendButtonIcon.png"

import "./styles.scss"

const API_URL = "/mail/contact-us"

const ContactUsPage = () => {
  const [isSend, setIsSend] = useState(false)
  const [disabledSendButton, setDisabledSendButton] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [state] = useGlobalState()
  const { register, handleSubmit, errors, reset } = useForm()

  // const clarificationText = "Aydınlatma metni 'ni okudum, kabul ediyorum."

  // const handleClickClarificaitonText = word => {
  //   console.log(word)
  //   setShowModal(true)
  // }

  // const renderClarificaitonText = () =>
  //   clarificationText.split(" ").map(word =>
  //     // eslint-disable-next-line react/jsx-key
  //     word === "Aydınlatma" || word === "metni" ? (
  //       word === "Aydınlatma" ? (
  //         <span
  //           className="clarificaiton-text"
  //           onClick={() => handleClickClarificaitonText(word)}
  //         >
  //           {word}
  //         </span>
  //       ) : (
  //         <span
  //           className="clarificaiton-text"
  //           onClick={() => handleClickClarificaitonText(word)}
  //         >
  //           {word}
  //         </span>
  //       )
  //     ) : (
  //       <span
  //         className="clarificaiton-text-rest"
  //         onClick={() => handleClickClarificaitonText(word)}
  //       >
  //         {word}{" "}
  //       </span>
  //     )
  //   )

  const onSubmit = data => {
    axios({
      method: "post",
      url: API_URL,
      data: {
        fullname: data["yourName"],
        email: data["yourMail"],
        message: data["yourMessage"],
      },
    })
      .then(() => {
        setDisabledSendButton(true)
        reset(),
          setTimeout(() => {
            setDisabledSendButton(false)
          }, 2000)
      })
      .catch(error => console.log(error.response))
  }

  return (
    <>
      <Container className="main-container">
        <div className="application">
          <Helmet title={getLanguageFile(state.language).Title.contactUs}>
            <meta charSet="utf-8" />
          </Helmet>
        </div>
        <Header />
        <Container>
          <Container>
            <Row>
              <Col className="col-lg-6 col-md-6 col-12">
                <InfoCard
                  customStyle="first-row"
                  cardTitle={getLanguageFile(state.language).ContactUs.address}
                  cardDescription="Zafer Sb Mah. Nilüfer Sok. No:32 İç Kapı No:301 Gaziemir/ İZMİR"
                  // cardDescription="İzmir Teknoloji Geliştirme Bölgesi,  A10 Binası No:19, 35433,
                  // Urla-İZMİR"
                />
                <InfoCard cardDescription="İzmir Teknoloji Geliştirme Bölgesi, A10 Binası No:19, 35433, Urla/ İZMİR" />
                <InfoCard
                  cardTitle={getLanguageFile(state.language).ContactUs.mail}
                  cardDescription="info@etetechnology.com"
                />
                <InfoCard
                  cardTitle={getLanguageFile(state.language).ContactUs.phone}
                  cardDescription="+90 (232) 502 86 03"
                />
                <InfoCard
                  cardTitle={getLanguageFile(state.language).ContactUs.fax}
                  cardDescription="+90 (232) 761 03 94"
                />
                <InfoCard
                  customStyle="last-row"
                  cardTitle={
                    getLanguageFile(state.language).ContactUs.workingHours
                  }
                  cardDescription="09:00 - 17:30, GMT+3"
                />
              </Col>
              <Col className="form-col col-lg-6 col-md-6 col-12">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Row>
                    <Col>
                      <Form.Control
                        name="yourName"
                        placeholder={
                          getLanguageFile(state.language).ContactUs
                            .yourNamePlaceholder
                        }
                        ref={register({
                          required: true,
                        })}
                      />
                      {errors.yourName && (
                        <p>
                          {getLanguageFile(state.language).ContactUs.isRequired}
                        </p>
                      )}
                    </Col>
                    <Col>
                      <Form.Control
                        name="yourMail"
                        placeholder={
                          getLanguageFile(state.language).ContactUs
                            .yourMailPlaceholder
                        }
                        ref={register({
                          required: true,
                          pattern: /.+@.+\.[A-Za-z]+$/,
                        })}
                      />
                      {errors.yourMail?.type == "required" && (
                        <p>
                          {getLanguageFile(state.language).ContactUs.isRequired}
                        </p>
                      )}
                      {errors.yourMail?.type == "pattern" && (
                        <p>
                          {
                            getLanguageFile(state.language).ContactUs
                              .invalidEmail
                          }
                        </p>
                      )}
                    </Col>
                  </Form.Row>
                  <Form.Control
                    name="yourMessage"
                    placeholder={
                      getLanguageFile(state.language).ContactUs
                        .yourMessagePlaceholder
                    }
                    as="textarea"
                    rows="10"
                    ref={register}
                  />
                  <div className="container">
                    {/* <div className="checkbox">
                      <input
                        ref={register({
                          required: true,
                        })}
                        name="clarificationText"
                        type="checkbox"
                        id="checkbox"
                        onChange={e => {
                          if (e.target.checked) {
                            setShowModal(true)
                          }
                        }}
                      />
                      <label htmlFor="checkbox">
                        {
                          getLanguageFile(state.language).ContactUs
                            .clarificationText
                        }
                      </label>
                    </div> */}
                    <div className="clarificationTextStyle">
                      <input type="checkbox" id="agree" />
                      {state.language === "tr" ? (
                        <>
                          <span
                            onClick={e => {
                              e.stopPropagation()
                              setShowModal(true)
                            }}
                          >
                            <b>
                              {
                                getLanguageFile(state.language).ContactUs
                                  .clarificationTextPopup
                              }
                            </b>
                          </span>
                          <label
                            htmlFor="agree"
                            className="clarificationLabelStyle"
                          >
                            {
                              getLanguageFile(state.language).ContactUs
                                .clarificationText
                            }
                          </label>
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="agree"
                            className="clarificationLabelStyle"
                          >
                            {
                              getLanguageFile(state.language).ContactUs
                                .clarificationText
                            }
                          </label>

                          <span
                            onClick={e => {
                              e.stopPropagation()
                              setShowModal(true)
                            }}
                          >
                            <b>
                              {
                                getLanguageFile(state.language).ContactUs
                                  .clarificationTextPopup
                              }
                            </b>
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  {errors.clarificationText && (
                    <p className="checkbox-warning">
                      {getLanguageFile(state.language).ContactUs.isRequired}
                    </p>
                  )}
                  <Row className="send-button-container">
                    <Button
                      className="send-button"
                      type="submit"
                      onClick={() => setIsSend(!isSend)}
                    >
                      {disabledSendButton ? (
                        <img
                          className="send-button-icon"
                          src={SEND_BUTTON_ICON}
                        />
                      ) : (
                        getLanguageFile(state.language).ContactUs.sendButton
                      )}
                    </Button>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>

      <Map
        style={{
          width: "100%",
          height: 318,
          marginTop: 100,
          marginBottom: 100,
          zIndex: 0,
        }}
        className="leaflet-container"
        center={[38.335966, 27.133478]}
        zoom={11}
      >
        <TileLayer
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[38.315113, 26.637566]}>
          <Popup>
            Ete Deney Eğitim ve Değerlendirme Teknolojileri Anonim Şirketi
          </Popup>
        </Marker>
        <Marker position={[38.333848, 27.126366]}>
          <Popup>
            Ete Deney Eğitim ve Değerlendirme Teknolojileri Anonim Şirketi
            Merkez Ofis
          </Popup>
        </Marker>
      </Map>

      <Footer />
      <ClarificaitonModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  )
}

export default ContactUsPage
